<template>
  <div>
    <v-card flat class="mt-10">
      <v-tabs vertical class="py-4">
        <v-tab
          class="justify-start"
          v-for="section in sections"
          :key="section.title"
          style="width: 250px;"
          @click="section.link && $router.push(section.link)"
        >
          <v-icon left v-if="section.icon" color="primary">
            mdi-{{ section.icon }}
          </v-icon>
          <span class="mx-1 text-body-1 primary--text">{{
            $t(section.title)
          }}</span>
        </v-tab>

        <v-tab-item>
          <v-card flat>
            <v-card-text class="py-0">
              <div>
                <ProfileInfo />
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text class="py-0">
              <div>
                <ChangePassword />
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
export default {
  components: {
    ProfileInfo: () => import('./ProfileInfo'),
    ChangePassword: () => import('./ChangePassword'),
  },
  data() {
    return {
      sections: [
        { title: 'general', icon: 'account' },
        { title: 'security', icon: 'lock' },
        { title: 'myLearning', icon: 'school', link: '/my-courses?type=1' },
        { title: 'myTests', icon: 'head-question', link: '/my-tests?type=2' },
      ],
    };
  },
};
</script>

<style></style>
